import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {title: "Home"}
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: ()=> import('../views/TestView.vue'),
  //   meta: {title: "Test"}
  // },
  {
    path: '/register',
    name: 'Register',
    component: ()=> import('../views/RegisterView.vue'),
    meta: {title: "Buat Akun"}
  },
  {
    path: '/registration-process/:phoneNumber',
    name: 'RegistrationProcess',
    component: ()=> import('../views/RegistrationProcessView.vue'),
    meta: {title: "Proses Pendaftaran"}
  },
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('../views/LoginView.vue'),
    meta: {title: "Login"}
  },
  {
    path: '/login/password-reset',
    name: 'PasswordReset',
    component: ()=> import('../views/PasswordResetView.vue'),
    meta: {title: "Lupa Kata Sandi"}
  },
  {
    path: '/login/password-reset/:token',
    name: 'PasswordResetVerify',
    component: ()=> import('../views/PasswordResetVerifyView.vue'),
    meta: {title: "Pengaturan Ulang Kata Sandi"}
  },
  {
    path: '/admin',
    name: 'Admin',
    component: ()=> import('../views/DashboardView.vue'),
    meta: {
      guard: true
    },
    children: [
      {
        path: "",
        name: "AdminHome",
        component: () => import("../views/AdminHomeView.vue"),
        meta: {
          title: "Dashboard",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "setting/account",
        name: "Account",
        component: () => import("../views/AccountView.vue"),
        meta: {title: "Akun"}
      },
      {
        path: "transaction-report/check",
        name: "TransactionCheck",
        component: () => import("../views/TransactionCheckView.vue"),
        meta: {title: "Cek Transaksi"}
      },
      {
        path: "transaction-report/history",
        name: "TransactionHistory",
        component: () => import("../views/TransactionHistoryView.vue"),
        meta: {title: "Riwayat Transaksi"}
      },
      {
        path: "transaction-report/summary",
        name: "TransactionSummary",
        component: () => import("../views/TransactionSummaryView.vue"),
        meta: {title: "Ringkasan Transaksi"}
      },
      {
        path: "product/status",
        name: "ProductStatus",
        component: () => import("../views/ProductStatusView.vue"),
        meta: {title: "Status Produk"}
      },
      {
        path: "product/list",
        name: "ProductList",
        component: () => import("../views/ProductListView.vue"),
        meta: {title: "Daftar Produk"}
      },
      {
        path: "product/profit",
        name: "ProductProfit",
        component: () => import("../views/ProductProfitView.vue"),
        meta: {title: "Profit Produk"}
      },
      {
        path: "balance/deposit-history",
        name: "DepositHistory",
        component: () => import("../views/DepositHistoryView.vue"),
        meta: {title: "Riwayat Deposit"}
      },
      {
        path: "balance/debit-credit",
        name: "BalanceDebitCredit",
        component: () => import("../views/BalanceDebitCreditView.vue"),
        meta: {title: "Balance Debit Credit"}
      },
      {
        path: "balance/topup",
        name: "TopUp",
        component: () => import("../views/TopUpView.vue"),
        meta: {title: "Top Up"}
      },
      {
        path: "balance/bank-transfer-topup",
        name: "BankTransferTopup",
        component: () => import("../views/BankTransferTopupView.vue"),
        meta: {title: "Konfirmasi Topup Manual"}
      },
      {
        path: "balance/auto-withdrawal",
        name: "BalanceAutoWithdrawal",
        component: () => import("../views/BalanceAutoWithdrawalView.vue"),
        meta: {title: "Penarikan Otomatis"}
      },
      {
        path: "setting/log",
        name: "Log",
        component: () => import("../views/LogView.vue"),
        meta: {title: "Log"}
      },
      {
        path: "transaction/transfer",
        name: "Transfer",
        component: () => import("../views/TransferView.vue"),
        meta: {
          title: "Transfer",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "transaction/transfer/spreadsheet",
        name: "TransferSpreadsheet",
        component: () => import("../views/TransferSpreadsheetView.vue"),
        meta: {
          title: "Transfer",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "transaction/transfer/manual-input",
        name: "TransferManualInput",
        component: () => import("../views/TransferManualInputView.vue"),
        meta: {
          title: "Transfer",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "transaction/transfer/payment",
        name: "TransferPayment",
        component: () => import("../views/TransferPaymentView.vue"),
        meta: {
          title: "Transfer",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "transaction/transfer/send",
        name: "TransferSend",
        component: () => import("../views/TransferSendView.vue"),
        meta: {
          title: "Transfer",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "transaction/refund",
        name: "RefundList",
        component: () => import("../views/RefundListView.vue"),
        meta: {title: "Daftar Refund"}
      }
    ]
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ()=> import('../views/ContactUsView.vue'),
    meta: {title: "Hubungi Kami"}
  },
  {
    path: "/not-found",
    name: 'NotFound',
    component: ()=> import('../views/NotFoundView.vue'),
    meta: {title: "Not Found"}
  },
  { path: "/:catchAll(.*)", redirect: '/not-found' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
